import Vue from 'vue'
import { get } from '@/vue/store/_ajax'

export default {
	namespaced: true,
	state: {
		loading: false,
		url: '',
		slug: '',
		title: '',
		image: '',
		linkUrl: '',
		id: '',
		questions: [],
		nextUrl: '',
    skipFinish: false,
		entryType: '',
		customMessage: '',
		smallPrint: '',
	},
	getters: {
		finalUrl(state) {
      if(state.skipFinish) {
        return false;
      }
			// this is a vue router url...not a real one.
			return `/${state.slug}/finish/`
		},
		detailsUrl(state) {
			// this is a vue router url...not a real one.
			return `/${state.slug}/details/`
		},
		totalQuestions(state) {
			return state.questions.length
		},

		questionById: state => _qid => {
			const found = state.questions.filter(({ qid }) => {
				return qid == _qid
			})

			if (found) return found[0]
			return false
		},
	},
	mutations: {
		questions(state, questions) {
			Vue.set(state, 'questions', questions)
		},

		loadAnswersForQuestion(state, { qid: _qid, answers }) {

			// 1. find question in Array
			// Don't use === as we can't guarante string / int IDs.
			const found = state.questions.filter(({ qid }) => qid == _qid)

			if (found.length) {
				const question = found[0];
				Vue.set(question, 'answers', answers)
			}

		},
		loading(state, isLoading) {
			state.loading = isLoading
		},
		load(state, { title, url, id, slug, image, questions, entryType, customMessage, smallPrint }) {
			state.title = title
			state.url = url
			state.id = id
			state.slug = slug
			state.image = image
			state.entryType = entryType
			state.customMessage = customMessage
			state.smallPrint = smallPrint

			Vue.set(state, 'questions', questions)

		},
		unload( state ) {
				state.loading = false
				state.url = ''
				state.slug = ''
				state.title = ''
				state.image = ''
				state.id = ''
				state.questions = []
				state.nextUrl = ''
				state.entryType = ''
				state.customMessage = ''
				state.smallPrint = ''
		}
	},
	actions: {
		async load({ commit, state }, slug) {

      const params = new URLSearchParams(window.location.search);
      if(params.get('skipFinish')) {
        console.log('TRUE');
        state.skipFinish = true;
      }

      // Just use what we have cached....
			if (state.slug === slug) {
				return
			}

			commit('loading', true)
			const { data: response } = await get('polls/get-poll', { slug })
			commit('loading', false)

			if (response.success) {
				commit('load', response.data)
			} else {
				commit('errors/alert', response.message, { root: true })
			}
		}
	}
};
